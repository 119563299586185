@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "mixins/mixins";

.hp-banner {
    text-align: center;

    img {
        @media (min-width: 768px) {
            width: 30.5%;
        }
    }
}